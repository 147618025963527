import DomainContextParams from '@/utils/types/DomainContextParams';

export const PRODUCT_ENTITY_INFO_LINKS_FRAGMENT = `
  fragment productEntityInfoLinksFragment on Product {
    id
    uid
    name
    images(first: 1) {
      fullFileResource {
        schemaCode
        path
      }
    }
    categories: categoriesInContext(context: "${DomainContextParams.product}") {
      uid
      name
    }
  }
`;
